.toggleMenu_1 {
  display: none;
  padding: 10px 15px;
}
.navbar-brand {
  padding: 20.5px 0 !important;
}
.nav_1 {
  @media screen and (min-width: 1041px) {
  display: block!important;
  }
  @media screen and (min-width: 1040px) {
    height: auto!important;
    left: 0;

  }
  z-index: 13;
  position: relative;
  float: right;
  list-style: none;
  *zoom: 1;
  right: -100%;
  background: transparent;
  margin-top: 15px;
  margin-bottom: 0;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a {
    color: #434242;
    font-size: 14px;
    padding: 0;
    line-height: 25px;
    text-decoration: none;
    font-weight: bold;
    text-align: left;
      &:hover {
        color: $brand-primary;
      }
  }

  li {
    position: relative;
    li {
      @media screen and (min-width: 1040px) {
        .parent {
          &:after {
            content: '';
            background: url("../../img/sprite.png") 6px 5px no-repeat;
            float: right;
            display: block;
            width: 12px;
            height: 20px;
          }
        }
      }
      a {
        white-space: normal;
        background-color: #f6f3f3;
        padding: 14px 16px 14px 12px;
        min-width: 130px;
        display: block;
        border: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        border-top: 1px solid #fff;
        color: #434242;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.25;
      }
      &.hover {
        ul {
          border-top: 1px solid #e3e3e3;
          left: 100%;
          top: -1px;
        }
      }
    }
    ul {
      position: absolute;
      left: -9999px;
      border-top: 1px solid #e3e3e3;
        li a:hover {
          color: #0fa00f;
          background-color: #fff;
        }
    }

  }

  & > li {
    @media screen and (min-width: 1040px) {
      padding-right: 25px;
      &:last-child {
        padding-right: 0;
      }
    }
    float: left;
    @media screen and (min-width: 1040px) {
      &.parent1>a,& > .parent {
        background: url("../../img/downArrow.png") no-repeat 100% 11px;
        padding-right: 15px;
      }
      & ul {
        margin-left: -1px;
      }
    }
    &.hover {
      & > ul {
        left: 0;
      }
    }
    & > a {
      display: block;
    }
    & ul {
      min-width: 160px;
    }
  }
}
@media screen and (max-width: 960px) {
  .navbar-brand {
    width: 100%;
    max-width: 175px;
  }
}
@media screen and (max-width: 1040px) {
  .navbar {
    margin-bottom: 10px;
  }
  .nav_1{
    margin-top: 1px;
    margin-bottom: 0;
    overflow-y: auto;
  }

  #mobile-menu-btn{
    display: inline-block;
    float: right;
    width: 40px;
    height: 29px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  #mobile-menu-btn span{
    display: block;
    position: absolute;
    height: 1px;
    width: 22px;
    background: #888888;
    opacity: 1;
    left: 8px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  #mobile-menu-btn span:nth-child(1) {
    top: 8px;
  }
  #mobile-menu-btn span:nth-child(2),
  #mobile-menu-btn span:nth-child(3) {
    top: 13px;
  }
  #mobile-menu-btn span:nth-child(4) {
    top: 18px;
  }
  #mobile-menu-btn.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  #mobile-menu-btn.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #mobile-menu-btn.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #mobile-menu-btn.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  .active {
    display: block;
  }
  .nav_1 {
    margin-top: 0;
    width: 100%;
    max-width:280px;
    height: 100%;
    display: block;


    ul {
      display: block;
      width: 100%;
    }
    & > li {
      float: none;
      & .parent {
        background: url("../../img/arrow-menu-green.png") no-repeat 95% 50%;
      }

    }
    li {
      &.hover{
        & >ul {
          display: block;
        }
        &>.parent {
          background: none;
        }
        background: #e8e8e8 url("../../img/arrow-top.png") no-repeat 95% 18px;
      }
      li{
        &.hover{
          .parent {
            background: none;
          }
          background: #f4f4f4 url("../../img/arrow-top.png") no-repeat 95% 18px;
        }
      }
      color: #434242;
      font-size: 18px;
      font-weight: bold;
      background-color: #e8e8e8;
      border-top:1px solid #fff;
      li{
        background-color: #f4f4f4;
        ul {
          display: none;
        }
        li{
          background-color: #fff;
          a{
            padding-left: 52px;
            background-color: #fff;
          }
        }
        a{
          padding-left: 35px;
          background-color: #f4f4f4;
          &.parent {
            background: url("../../img/arrow-menu-green.png") no-repeat 95% 50%;
          }
        }
      }
      a{
        padding: 10px 5px 5px 18px;
      }

      li {
        &.parent {
          background: url("../../img/arrow-menu-green.png") no-repeat 95% 50%;
        }
      }

    }

    & > li.hover > ul, & li li.hover ul {
      position: static;
    }
  }
  .mobile-menu-block .nav_1{
    top:81px;
    width: 100%;
    right: -100%;
    position: absolute;
    z-index: 12;
  }
}

.black-transparent-bg{
  background: #000;
  position: absolute;
  opacity: 0;
  display: none;
  z-index: 3;
  left: 0;
  height: 100%;
  top: 82px;
  width: 100%;
}
.link-wrap{
  display: inline-block;
}