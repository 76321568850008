.support-contact{
  margin-top: 20px;
}
.cwraper {
  position: relative;
  transition: top .4s linear;
  width: 260px;
  height: 250px;
  margin: 0 auto;
}
.cwraper:after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 245px;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 40px;
  background: url(../../img/shadow3.png) 50% 0 no-repeat;
  opacity: 1;
  -webkit-animation: secondsOut 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 0.01s;
  animation: secondsOut 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 0.01s;
}
.cwraper img {
  transition: top .4s linear;
  border: 5px solid #f6f3f3;
  border-radius: 50%;
}
.contact-row:hover .cwraper img {
  position: absolute;
  top: -20px;
  transition: top .4s linear;
}
.contact-row .text-center {
  position: relative;
  margin-top: 35px;
}
@media(max-width: 991px) {
  .contact-row .text-center {
    margin-bottom: 30px;
  }
}
.contact-row .text-center h4 {
  padding-bottom: 13px;
  font-weight: 700;
  font-size: 23px;
}
.contact-row:hover .cwraper:after {
  transition: all .4s linear;
  -webkit-animation: seconds 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 0.01s;
  animation: seconds 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 0.01s;
}
.contact-row:hover img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.contact-row img {
  top: 0px;
  left: 0px;
  position: absolute;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 50%;
  transition: 0.3s ease-in;
}
@-webkit-keyframes seconds {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes seconds {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes secondsOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes secondsOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
