.form-container {
  overflow: hidden;
  border-bottom: 3px solid #ccc;
  background: $form-container-background;
  padding: $form-container-padding;
  .form-title {
    font-size: $form-container-title-font-size;
    margin: 0;
    line-height: 1;
    font-weight: bold;
    font-family: $font-family-sans-serif;
    border-bottom: 1px solid $input-border;
    margin-bottom: 25px;
    padding-bottom: 10px;
  }
}

.form-horizontal .control-label {
  font-weight: normal;
  font-size: 18px;
  line-height: 45px;
  padding: 0 15px;
  margin: 0;
}

.validation-icon {
  display: none;
}

.has-error, .has-success {
  input {
    padding-right: 40px;
  }
  label {
    color: $text-color !important;
  }
  position: relative;
  .validation-icon {
    top: 9px;
    float: right;
    right: 25px;
    display: block;
    position: absolute;
    display: block;
    height: 27px;
    width: 27px;
    background: url(../../img/icons/error.svg) no-repeat;
  }
  .help-block {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 10px;
    strong {
      font-weight: normal;

    }
  }
  margin-bottom: 5px !important;
}

.form-group .help-block {
  display: none !important;
}

.has-success {
  margin-bottom: 25px !important;;
  .validation-icon {
    background: url(../../img/icons/success.svg) no-repeat;
  }
  .help-block {
    display: none !important;
  }
}

.has-error {
  .help-block {
    display: block !important;
  }
}

.required:after {
  color: $brand-danger;
  content: "*";
  margin-left: 3px;
  position: absolute;
}

.if-legal.divider, .if-person.divider {
  border-bottom: 2px lighten(#ccc, 12%) solid;
  margin: 0 -30px 20px;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 17px;

}

.entity-hide {
  position: relative;
  background: white;
  max-width: 880px;
  padding: 0 30px 30px;
  margin: 0 auto;
  .trigger {
    display: none;
  }

  &.legal {
    .if-person {
      display: none;
    }

  }
  &.person {
    .if-legal {
      display: none;
    }

  }
}

.form-control:focus {
  border-color: #938f9d;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3), inset 0 0 1px 0 #938f9d;

}

.has-error .form-control:focus {
  border-color: rgba(201, 13, 13, 0.65);
  box-shadow: inset 0 1px 1px rgba(201, 13, 13, 0.3), inset 0 0 1px 0 rgba(201, 13, 13, 0.65);
}

.has-success .form-control:focus {
  border-color: rgba(43, 84, 44, 0.57);
  box-shadow: inset 0 1px 1px rgba(43, 84, 44, 0.3), inset 0 0 1px 0 rgba(43, 84, 44, 0.57);

}

.checkbox {

  label {
    padding-left: 0;
  }
  .replacer {
    background: url("../../img/icons/checkboxes.png") no-repeat -18px 0;
    height: 20px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
    width: 20px;
    margin-right: 10px;
  }

  input[type=checkbox] {
    display: none;
    &:checked + .replacer {
      display: block;
      background: url("../../img/icons/checkboxes.png") no-repeat -38px 0;
    }
  }
  &.disabled {
    label {
      .replacer {
        background: url("../../img/icons/checkboxes.png") no-repeat -59px 0;
      }
      &:hover .replacer {
        background: url("../../img/icons/checkboxes.png") no-repeat -59px 0;
      }
      input[type=checkbox] {
        display: none;
        &:checked + .replacer {
          display: block;
          background: url("../../img/icons/checkboxes.png") no-repeat -80px 0;
        }
        &:hover .replacer {
          background: url("../../img/icons/checkboxes.png") no-repeat -80px 0;
        }
      }
    }
  }
}

.replacer {
  height: 20px;
  display: block;
  float: left;
  position: relative;
  top: 3px;
  width: 20px;
  margin-right: 5px;
}

.checkbox {
  label {
    padding-left: 0;
    &:hover .replacer {
      background: url("../../img/icons/checkboxes.png") no-repeat -19px 0;
    }
    .replacer {
      background: url("../../img/icons/checkboxes.png") no-repeat 1px 0;
    }
    input[type=checkbox] {
      display: none;
      &:checked + .replacer {
        display: block;
        background: url("../../img/icons/checkboxes.png") no-repeat -39px 0;
      }
    }
  }

}

.radio {
  label {

    padding-left: 0;
    margin-left: 50px;
    &:first-child {
      margin-left: 0;
    }
    &:hover .replacer {
      background: url("../../img/icons/checkboxes.png") no-repeat -120px 0;
    }
    .replacer {
      background: url("../../img/icons/checkboxes.png") no-repeat -100px 0;
    }

    input[type=radio] {
      display: none;
      &:checked + .replacer {
        display: block;
        background: url("../../img/icons/checkboxes.png") no-repeat -140px 0;
      }
    }
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number], input[type=date] {
  -moz-appearance: textfield;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}

input[type="date"] {
  padding-left: 45px;
  &:before {
    content: "\f073";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/

    font-size: 18px;
    padding-right: 0.5em;
    position: absolute;
    top: 0px;
    left: 30px;
  }
}

.well .checkbox-inline {
  padding-top: 6px;
  margin-left: -5px;
  .replacer {
    top: 0;
  }
}

.ns-zone .input-group {
  .btn {
    padding: 12px;
  }
}

.file-input-name {
  margin-left: 8px;
  position: absolute;
  font-size: 13px;
  padding-left: 20px;
}

.topModal {
  .modal-dialog {
    .modal-content {
      background-color: #fffcfc;
    }
    max-width: 520px;
  }
}

.shadow-sm {
  border-width: 8px;
  border-radius: 6px;
}

.btn-outline {
  &.btn-xs{
    font-size: 16px;
  }

 height: auto !important;
}
.btn-group.open{
  .btn-outline {
    color:white!important;
  }
}
.btn-caret {
  margin-left: 5px;
  margin-right: -5px;
}
.with-space{
  margin:0 5px!important;
}
