@import "mamikon/_config";
@import "mamikon/_navbar";
@import "mamikon/_title";
@import "mamikon/_form";
@import "mamikon/_tarrifSwicher";
@import "mamikon/_balance";
@import "mamikon/_domainSearch";
@import "mamikon/_invoice";
@import "mamikon/_table";
@import "mamikon/_modal";
@import "mamikon/_buttons";
@import "mamikon/_notifications";
@import "mamikon/_tarifChoose";
@import "mamikon/_whois";
@import "mamikon/_transfer";

.loader {
  div {
    position: absolute;
    width: 400px;
    height: 300px;
    top: 50%;
    left: 50%;
    margin: -150px 0 0 -200px;
    text-align: center;
  }

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 145px 2px 0;
    background: rgba(15, 160, 15, 0.25);
    border-radius: 50%;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);

    animation: wave 2s infinite ease-in-out;
    -moz-animation: wave 2s infinite ease-in-out;
    -webkit-animation: wave 2s infinite ease-in-out;
  }

  @keyframes wave {
    0%, 60%, 100% {
      background: rgba(15, 160, 15, 0.3);
      transform: translateY(0);
      -moz-transform: translateY(0);
    }

    20% {
      background: rgba(15, 160, 15, 0.75);
      transform: translateY(10px);
      -moz-transform: translateY(10px);
    }

    40% {
      background: rgba(15, 160, 15, 0.75);
      transform: translateY(-10px);
      -moz-transform: translateY(-10px);
    }

  }

  @-webkit-keyframes wave {
    0%, 60%, 100% {
      background: rgba(215, 160, 15, 0.25);
      transform: translateY(0);
      -webkit-transform: translateY(0);
    }

    20% {
      background: rgba(15, 160, 15, 0.75);
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
    }

    40% {
      background: rgba(15, 160, 15, 0.75);
      transform: translateY(-10px);
      -webkit-transform: translateY(-10px);
    }
  }

  span:nth-child(1) {
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
  }

  span:nth-child(2) {
    animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
  }

  span:nth-child(4) {
    animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
  }

  span:nth-child(5) {
    animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
  }
}

.go-to-back {
  position: fixed;
  width: 100%;
  top: 0;
  height: 80px;
  a {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
    background-color: $brand-primary;
    color: white;
    text-align: center;
    font-size: 11px;
  }

}

.navbar-default .navbar-nav > li.text-primary a {
  color: $brand-primary;
}

#obo_slider .oneByOne_item {
  width: 1040px !important;
}

@media only screen and (max-width: 959px) {
  body .oneByOne1 {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: $container-sm) {
  .btn-primary-outline.btn-sm{
    padding:6px 15px;
  }
  #cart-zone.affix, #configure-total.affix {
    top: 0;
    position: relative;
    max-width: 100%;
  }
  #current-cart #delete-selected{
    display: none;
  }
  .remove-div{
    position: absolute;
    right: 33px;
    margin-top: 2px;
  }
}
body .footer .container-fluid {
  max-width: 1170px !important;
}

.well .well-title .text-muted {
  line-height: 1.5;
  font-weight: normal;
}
