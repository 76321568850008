@media screen and (max-width: 768px) {
  .domain-search-btn {
    padding : 12px 10px;
  }
}

@media screen and (max-width: 992px) {
  .copyright {

    .row-footer {
      position: relative;

      .footer-nav {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
      }
    }

    ul {
      float: none !important;
      display: block;
      padding-left: 0;
      text-align: center;

      li {
        display: block;
        margin: 7px 0;
      }
    }
  }
}